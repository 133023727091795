import "../styles/filter-dropdown.scss";
import React from "react";
import Popover from "./popover";
import { GuiSelect } from "./global-ui/gui-select";
import { GuiButton } from "./global-ui/gui-button";
import { debounce, useUpdateState } from "../utilities/utilities";
import { Icon } from "./icon";
import { useSelector } from "react-redux";
import { useState } from "react";

export function FilterDropdown({ filters, onFilterChange }) {
  let [state, updateState] = useUpdateState({});
  let isMobile = useSelector((store) => store.page.isMobile);
  const [isApplyDisabled, setIsApplyDisabled] = useState(false);

  function togglePopup() {
    let changes = { showFilter: !state.showFilter };
    filters.forEach((f) => (changes[f.name] = null));
    updateState(changes);
  }

  function clearFilters() {
    onFilterChange("clear");
    updateState("showFilter", false);
    setIsApplyDisabled(false);
  }

  function applyFilter() {
    let values = [];
    filters
      .filter((f) => state[f.name])
      .forEach((f) => values.push({ name: f.name, ...state[f.name] }));
    onFilterChange("add-filters", values);
    updateState("showFilter", false);
    setIsApplyDisabled(true);
  }

  return (
    <>
      <div className="popover-container">
        <Popover
          show={state.showFilter}
          className={
            "no-arrow filter-popover" + (isMobile ? " anchor-right" : "")
          }
          onHide={() => updateState("showFilter", false)}
        >
          {filters.map((f, i) => (
            <GuiSelect
              label={f.name}
              name={f.name}
              options={f.options}
              value={state[f.name]?.value}
              onChange={updateState}
              key={i}
            />
          ))}
          <div className="buttons">
            <GuiButton className="btn-text" onClick={clearFilters}>
              Clear
            </GuiButton>
            {/* <GuiButton onClick={applyFilter}>Apply</GuiButton> */}
            <GuiButton
              onClick={applyFilter}
              disabled={isApplyDisabled}
              className={isApplyDisabled ? "btn-disabled" : ""}
            >
              Apply
            </GuiButton>
          </div>
        </Popover>
      </div>
      {isMobile ? (
        <i
          className="fas fa-filter"
          onClick={(e) => debounce(togglePopup)}
          data-qa="filter-button"
        ></i>
      ) : (
        <GuiButton
          className={
            "btn-outline filter-button" + (state.showFilter ? " active" : "")
          }
          onClick={togglePopup}
          dataQa="filter-button"
        >
          Filter By <Icon icon="chevron-down" weight="400" />
        </GuiButton>
      )}
    </>
  );
}
